<template>
  <div id="skullDownload">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">
            3D череп в <br />
            формате STL
          </h1>
        </div>
      </div>
    </div>
    <div class="skullDownloadBlock">
      <div class="wrapper">
        <div class="skullDownloadBlock-goBack">
          <router-link :to="{ name: 'library3d' }">
            <icon name="chevron-left" scale="1.8" class="mr-50"></icon>
            <span>Вернуться к 3D библиотекам</span>
          </router-link>
        </div>
        <div class="skullDownloadBlock-info">
          Полная анатомия черепа человека в деталях состоит из "56" файлов. Предназначена для изучения анатомии черепа,
          а так же для 3D печати и создания типадонтов.
        </div>
        <div class="skullDownloadBlock-preview">
          <div class="skullDownloadBlock-preview-img">
            <!-- <img src="./../../assets/images/library3d/skull.jpg" alt=""> -->
            <iframe
              width="100%"
              height="auto"
              src="https://www.youtube.com/embed/fDvF9wfMAEw"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            ></iframe>
          </div>
          <div class="skullDownloadBlock-preview-download">
            <router-link
              :to="{ name: isLoggedIn ? 'profile' : 'signin' }"
              v-if="!isLoggedIn || !isPremium"
              class="downloadBtn"
            >
              Для скачивания необходим премиум статус
            </router-link>
            <a
              v-if="isLoggedIn && isPremium"
              href="https://s3.eu-central-1.amazonaws.com/dentabula/skull_3D_preview/Skull+Pogarskiy+Design.zip"
              class="downloadBtn"
              download="photoItem.title"
            >
              Скачать
            </a>
            <div class="skullDownloadBlock-preview-shortcut">
              <span class="skullDownloadBlock-preview-shortcut-link">Просмотреть</span>
              <div class="skullDownloadBlock-preview-shortcut-list">
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b293ca4f8acfc2833153055' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_1.jpg" alt="" />
                </router-link>
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b293e2ff8acfc2833153056' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_2.jpg" alt="" />
                </router-link>
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b293ef8f8acfc2833153057' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_3.jpg" alt="" />
                </router-link>
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b294017f8acfc2833153058' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_4.jpg" alt="" />
                </router-link>
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b294121f8acfc2833153059' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_5.jpg" alt="" />
                </router-link>
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b294166f8acfc283315305a' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_6.jpg" alt="" />
                </router-link>
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b2942bef8acfc283315305b' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_7.jpg" alt="" />
                </router-link>
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b2945acf8acfc283315305c' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_8.jpg" alt="" />
                </router-link>
                <router-link
                  :to="{ name: 'viewerVRremote', params: { set: '5b294611f8acfc283315305d' } }"
                  class="skullDownloadBlock-preview-shortcut-list-item"
                >
                  <img src="./../../assets/images/library3d/skull_preview_9.jpg" alt="" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";

export default {
  name: "Skull3D",
  components: { mainMenu, mainFooter },
  data() {
    return {
      endpoint: "photo_bank/",
      profileStatusEndpoint: "profile/status/",
      isPremium: false,
      photoItem: {
        title: "",
        extention: "",
        size: "",
        width: "",
        height: "",
        preview: "",
        download: "",
      },
      banner: "Место для вашей рекламы",
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getProfile() {
      this.$http.post(this.profileStatusEndpoint).then((response) => {
        if (response.data.isPremium) {
          this.isPremium = true;
        } else {
          this.isPremium = false;
        }
      });
    },
    getPhoto() {
      this.$http.get(this.endpoint + this.$route.params.photoId).then((response) => {
        if (response.data.photoBankItem) {
          this.photoItem.title = response.data.photoBankItem.title;
          this.photoItem.extention = response.data.photoBankItem.fileExtention;
          this.photoItem.size = response.data.photoBankItem.size;
          this.photoItem.width = response.data.photoBankItem.width;
          this.photoItem.height = response.data.photoBankItem.height;
          this.photoItem.preview = response.data.photoBankItem.preview;
          this.photoItem.download = response.data.photoBankItem.download;
        } else {
          this.photoItem = null;
          console.log("Photo is not find!");
        }
      });
    },
  },
  created() {
    this.getProfile();
  },
};
</script>

<style lang="sass">
#skullDownload
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .headerPreview
		display: flex
		min-height: 317px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/library3d/skullBG.jpg')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			@media screen and (max-width: 426px)
				display: flex
				flex-direction: column
				align-items: center
				width: 100%
				text-align: center
			& .sectionTitle
				color: #000
				font-weight: lighter
				font-size: 3rem
				padding-bottom: 1.5rem
				margin: 0 0 1.5rem
				text-shadow: 1px 1px 5px #000
				// border-bottom: 1px solid #f7941d
				@media screen and (max-width: 426px)
					font-size: 1.7rem
					padding-bottom: 0.5rem
					margin: 0 0 0.5rem
			& .btnAbout
				background: #f7941d
				color: #fff
				display: block
				padding: 0.5rem 2rem
				border: 1px solid transparent
				cursor: pointer
			& .sectionDescription
				font-family: 'Ropa Sans Pro SC Regular'
				font-weight: 400
				font-size: 1.3rem
				color: #fff
				margin-top: 1.5rem
				text-shadow: 1px 1px 5px #000
	& .skullDownloadBlock
		& .skullDownloadBlock-goBack
			margin-bottom: 0.5rem
			& a
				display: inline-flex
				align-items: center
				padding: 0.5rem 0
				font-size: 18px
				color: #333
				transition: all 0.5s
				&:hover
					color: #5f5f5f
		& .skullDownloadBlock-info
			color: #333
		& .skullDownloadBlock-preview
			display: flex
			align-items: center
			margin: 1rem 0
			@media screen and (max-width: 768px)
				flex-direction: column
			& .skullDownloadBlock-preview-img
				display: flex
				position: relative
				padding-bottom: calc(65% * 0.49)
				min-width: 65%
				padding-top: 25px
				height: 0
				border: 1px solid transparent
				@media screen and (max-width: 768px)
					width: 100%
					padding-bottom: calc(100% * 0.49)
				& iframe
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
			// & .skullDownloadBlock-preview-img
			// 	display: flex
			// 	flex-grow: 2
			// 	height: auto
			& .skullDownloadBlock-preview-download
				display: flex
				flex-direction: column
				width: 35%
				margin-left: 2rem
				@media screen and (max-width: 768px)
					width: 100%
					margin-top: 2rem
					margin-left: 0
				& .downloadBtn
					display: flex
					align-items: center
					justify-content: center
					width: 100%
					min-height: 38px
					margin-bottom: 0.5rem
					color: #fff
					background-color: #a864a8
					transition: all 0.5s
					padding: 5px
					text-align: center
					&:hover
						background-color: #bb50bb
				& .skullDownloadBlock-preview-shortcut
					& .skullDownloadBlock-preview-shortcut-link
						display: block
						text-align: center
						font-size: 18px
						margin-bottom: 0.5rem
						color: #a864a8
					& .skullDownloadBlock-preview-shortcut-list
						display: flex
						justify-content: space-between
						flex-wrap: wrap
						& .skullDownloadBlock-preview-shortcut-list-item
							display: flex
							width: calc((100% / 3) - 1rem)
							margin-bottom: 1rem
							& img
								display: block
								width: 100%
								height: 100%
								object-fit: cover
</style>
